import React from "react";
import styles from "./mosaico_styles.module.css";
import { ReactComponent as Mosaico } from "./mosaico.svg";

export default function MosaicoHome() {
  return (
    <div className={styles.div_mosaico}>
      <Mosaico className={`${styles.mosaico}`} />;
    </div>
  );
}

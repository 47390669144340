import React from "react";
import { useEffect, useState } from "react";
import styles from "./mosaico_personalized_styles.module.css";
import pared2 from "./pared2_v3.jpg";
import pared1 from "./pared1_v3.jpg";
import pared4 from "./pared4_v3.jpg";

const images = [pared2, pared1, pared4];

export default function MosaicoPersonalize() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div>
      <img
        className={`${styles.mosaico_personalize}`}
        src={images[currentIndex]}
      />
    </div>
  );
}

import React from "react";
import { useSpring, animated, config } from "@react-spring/web";
import styles from "./styles.module.css";
import ImagenFondo from "./FondoNosotros.jpg";

export default function FondoNosotros() {
  return (
    <div className={styles.div_fondo}>
      <img className={`${styles.fondo}`} src={ImagenFondo} />
    </div>
  );
}

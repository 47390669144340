import React from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import styles from "./styles.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare
} from "@fortawesome/free-brands-svg-icons";
import Logo from "./Logo";

import MosaicoHome from "./MosaicoHome";
import Navbar from "./Navbar";
import { useState, useEffect, useRef } from "react";

import MosaicoPersonalize from "../Personalize/MosaicoPersonalize";
import BotonPrueba from "./BotonPrueba";
import BotonTrabajo from "./BotonTrabajo";

import FondoNosotros from "../Nosotros/FondoNosotros";
import MosaicoNosotros from "../Nosotros/MosaicoNosotros";
import Corfo from "../Nosotros/Corfo.png";
import Valpo from "../Nosotros/Valpo.png";
import Core from "../Nosotros/Core.png";

import Carrusel from "../NuestroTrabajo/Carrusel";

export default function App() {
  const parallax = useRef();
  const [parallal, setParallal] = React.useState([]);

  const handleScroll = () => {
    const capa_1 = document.getElementById("Capa_1");
    const boton_1 = document.getElementById("boton_1");
    const boton_2 = document.getElementById("boton_2");
    const boton_3 = document.getElementById("boton_3");
    if (
      parallax.current.current <=
        parallax.current.space * 3 - parallax.current.space / 4 &&
      parallax.current.current >=
        parallax.current.space * 2 - parallax.current.space / 3
    ) {
      capa_1.style.fill = "#120a8f";
    } else {
      capa_1.style.fill = "#f9f9f9";
    }
    if (
      parallax.current.current <=
        parallax.current.space * 3 - parallax.current.space * 0.88 &&
      parallax.current.current >=
        parallax.current.space * 2 - parallax.current.space / 1.15
    ) {
      boton_1.style.color = "#120a8f";
      boton_2.style.color = "#120a8f";
      boton_3.style.color = "#120a8f";

      boton_2.style.textDecoration = "underline #120a8f 0.6vh";
      boton_2.style.textUnderlineOffset = "0.8vh";
      boton_1.style.textDecoration = "none";
      boton_3.style.textDecoration = "none";
    } else {
      boton_2.style.textDecoration = "none";

      boton_1.style.color = "#f9f9f9";
      boton_2.style.color = "#f9f9f9";
      boton_3.style.color = "#f9f9f9";
    }
    if (
      parallax.current.current >=
      parallax.current.space * 3 - parallax.current.space * 0.88
    ) {
      boton_3.style.textDecoration = "underline #f9f9f9 0.6vh";
      boton_3.style.textUnderlineOffset = "0.8vh";
    } else if (
      parallax.current.current <=
      parallax.current.space * 1 - parallax.current.space / 1.15
    ) {
      boton_1.style.textDecoration = "none";
    } else if (
      parallax.current.current <=
      parallax.current.space * 2 - parallax.current.space / 1.15
    ) {
      boton_1.style.textDecoration = "underline #f9f9f9 0.6vh";
      boton_1.style.textUnderlineOffset = "0.8vh";
    }
  };

  useEffect(() => {
    const container = document.querySelector(".parallax");
    container.addEventListener("scroll", handleScroll);

    // Intento de Scroll Snap
    var timer = null;
    container.addEventListener(
      "scroll",
      function () {
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          if (
            parallax.current.current !== 0 &&
            parallax.current.current !== parallax.current.space * 3
          ) {
            const targetOffset = Math.round(
              parallax.current.current / parallax.current.space
            );
            parallax.current.scrollTo(targetOffset);
          }
        }, 250);
      },
      false
    );
    setParallal(parallax);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const alignCenter = { display: "flex", alignItems: "center" };

  return (
    <div>
      <div className={styles.background} />

      <Parallax native="true" pages={4} ref={parallax} className="parallax">
        {/* Este es el Navbar*/}
        <ParallaxLayer
          native="true"
          sticky={{ start: 0, end: 3 }}
          style={{
            ...alignCenter,
            justifyContent: "left",
            pointerEvents: "none"
          }}
        >
          {/* Este es el Logo*/}

          <Logo {...parallal} />

          {/* Navbar*/}
          <Navbar {...parallal} />
        </ParallaxLayer>

        {/* Primera Pagina */}
        <ParallaxLayer native="true" offset={0} speed={0.8}>
          <MosaicoHome />
        </ParallaxLayer>

        <ParallaxLayer native="true" offset={0} speed={1}>
          <div className={styles.titulo_div_inicio}>
            <h1 className={styles.titulo}>Somos Dobro</h1>
          </div>
          <div className={styles.subtitulo_div_inicio}>
            <p className={styles.subtitulo}>
              Línea de revestimientos DOBRO, elegancia y diseño, azulejos de
              autor. Contamos con una alta gama en azulejos de distintos
              colores, diseños y texturas.
            </p>
          </div>
        </ParallaxLayer>
        {/* Esta es la pantalla blanca antes de la blanca*/}
        <ParallaxLayer
          native="true"
          speed={0.5}
          factor={1}
          offset={1.5}
          style={{ backgroundColor: "#f9f9f9" }}
        />

        {/* Fondo cambiante*/}
        <ParallaxLayer native="true" offset={1} speed={0.5}>
          <div>
            <MosaicoPersonalize />
          </div>
        </ParallaxLayer>
        {/* Texto Pantalla Personalize*/}
        <ParallaxLayer native="true" offset={1} speed={0.8}>
          <div className={styles.titulo_div_personaliza}>
            <h1 className={styles.titulo_personaliza}>
              Personaliza tu espacio
            </h1>
          </div>
          <div className={styles.subtitulo_div_personaliza}>
            <p className={styles.subtitulo}>
              Inspírate, sueña e imagina. Nuestros azulejos son capaces de
              transformar cualquier espacio y hacerlos únicos para ti. Prueba
              distintos diseños y colores, y elige el que mas te guste.
            </p>
          </div>
          <div className={styles.boton_div_personaliza}>
            <BotonPrueba />
          </div>
        </ParallaxLayer>
        {/* Esta es la pantalla blanca*/}

        <ParallaxLayer
          native="true"
          speed={0.5}
          factor={1.49}
          offset={2}
          style={{ backgroundColor: "#f9f9f9", pointerEvents: "none" }}
        />
        {/* Lo que va en la capa blanca */}
        <ParallaxLayer
          style={{ willChange: "transform" }}
          native="true"
          offset={2}
          speed={0.8}
        >
          <div className={styles.titulo_div_trabajo}>
            <h1 className={styles.titulo_trabajo}>Nuestro Trabajo</h1>
          </div>
          <div className={styles.subtitulo_div_trabajo}>
            <p className={styles.subtitulo_nosotros}>
              ¡Explora y enamórate! Te invitamos a revisar nuestro trabajo y
              encontrar la inspiracion que necesitabas para tus espacios.
            </p>
          </div>
          <div className={styles.boton_div_trabajo}>
            <BotonTrabajo />
          </div>

          <div className={styles.carrusel}>
            <Carrusel />
          </div>
        </ParallaxLayer>
        {/* Ultimas capas */}
        <ParallaxLayer native="true" offset={3} speed={0}>
          <FondoNosotros />
        </ParallaxLayer>
        <ParallaxLayer native="true" offset={3} speed={0.1}>
          <MosaicoNosotros />
        </ParallaxLayer>
        <ParallaxLayer native="true" offset={3} speed={0.5}>
          <div className={styles.titulo_div_nosotros}>
            <h1 className={styles.titulo_nosotros}>Quiénes Somos</h1>
          </div>
          <div className={styles.subtitulo_div_nosotros}>
            <p className={styles.subtitulo}>
              Prueba distintos azulejos y elige el que más te guste. Lorem ipsum
              dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
              nibh euismod tincidunt.
            </p>
          </div>
          <div className={styles.redes_div_nosotros}>
            <h3 className={styles.redes}>Contáctanos en nuestras redes:</h3>
          </div>
          <div className={styles.iconos_div}>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              className={styles.iconos}
              icon={faFacebookSquare}
              inverse
            />

            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              className={styles.iconos}
              icon={faTwitterSquare}
              inverse
            />
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open("https://www.instagram.com/dobro.chile/");
              }}
              className={styles.iconos}
              icon={faInstagramSquare}
              inverse
            />
          </div>
          <div className={styles.iconos_extra_div}>
            <img src={Corfo} className={styles.iconos_extra} alt="Corfo" />
            <img
              src={Valpo}
              className={styles.iconos_extra}
              alt="Municipalidad de Valparaiso"
            />

            <img src={Core} className={styles.iconos_extra} alt="Core" />
          </div>
        </ParallaxLayer>
      </Parallax>
    </div>
  );
}

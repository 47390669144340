import React from "react";
import Flickity from "react-flickity-component";

import "./flickity.css";

import card1 from "./card.jpg";
import card2 from "./card2.jpg";
import card3 from "./card3.jpg";
import card4 from "./card4.jpg";

// const imagenes = [card1, card2, card3, card4];
const flickityOptions = {
  pageDots: false,
  wrapAround: true,
  lazyLoad: true,
  lazyLoad: 2
};

export default function Carrusel() {
  return (
    <Flickity className={"carousel"} options={flickityOptions}>
      <CardCarrusel src={card1} name="Baños" />
      <CardCarrusel src={card2} name="Cocinas" />
      <CardCarrusel src={card3} name="Mosaicos" />
      <CardCarrusel src={card4} name="Revestimientos" />
    </Flickity>
  );
}

function CardCarrusel(props) {
  return (
    <div>
      <div className={"gradient"}>
        <img className={"carousel-image"} data-flickity-lazyload={props.src} />
      </div>
      <h1 className={"carousel-name"}>{props.name}</h1>
    </div>
  );
}

import React from "react";
import { useSpring, animated, config } from "@react-spring/web";
import styles from "./styles.module.css";
import { ReactComponent as Mosaico } from "./mosaico_azul.svg";

export default function MosaicoNosotros() {
  return (
    <div className={styles.div_mosaico}>
      <Mosaico className={`${styles.mosaico}`} />;
    </div>
  );
}

import React from "react";

import styles from "./styles.module.css";

export default function Navbar(props) {
  return (
    <div style={{ pointerEvents: "all" }} className={styles.div_nav}>
      <div className={styles.block_column}>
        <div className="columns is-mobile  ">
          <div className="column ">
            <button
              style={{ cursor: "pointer" }}
              onClick={() => props.current.scrollTo(1)}
              id="boton_1"
              className={styles.nav}
            >
              Personaliza tu espacio
            </button>
          </div>
          <div className="column ">
            <button
              style={{ cursor: "pointer" }}
              onClick={() => props.current.scrollTo(2)}
              id="boton_2"
              className={styles.nav}
            >
              Nuestro Trabajo
            </button>
          </div>
          <div className="column" style={{ marginLeft: "-2vmax" }}>
            <button
              style={{ cursor: "pointer" }}
              onClick={() => props.current.scrollTo(3)}
              id="boton_3"
              className={styles.nav}
            >
              Sobre Nosotros
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

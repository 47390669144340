import React from "react";
import styles from "./styles.module.css";

export default function BotonPrueba() {
  return (
    <div className={styles.div_boton}>
      <button
        type="button"
        onClick={() => {
          alert("alert");
        }}
        className={styles.boton}
      >
        ¡Pruebalo ahora!
      </button>
    </div>
  );
}

import React from "react";

import styles from "./styles.module.css";
import { ReactComponent as DobroLogo } from "./logo.svg";

export default function Logo(props) {
  return (
    <div style={{ pointerEvents: "all" }} className={styles.logo}>
      <button onClick={() => props.current.scrollTo(0)} className={styles.nav}>
        <DobroLogo
          style={{ cursor: "pointer" }}
          className={`${styles.block} ${styles.cls}`}
        />
      </button>
    </div>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "./Home/Home";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
